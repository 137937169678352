$font-color: rgb(128, 128, 128);

$primary: #ff3333FF;
$primary-t-90: #ff3333E6;
$primary-t-80: #ff3333CC;
$primary-t-70: #ff3333B3;
$primary-t-60: #ff333399;
$primary-t-50: #ff333380;
$primary-t-40: #ff333366;
$primary-t-30: #ff33334D;
$primary-t-20: #ff333333;
$primary-t-10: #ff33331A;

$secondary: #454545FF;
$secondary-t-90: #454545E6;
$secondary-t-80: #454545CC;
$secondary-t-70: #454545B3;
$secondary-t-60: #45454599;
$secondary-t-50: #45454580;
$secondary-t-40: #45454566;
$secondary-t-30: #4545454D;
$secondary-t-20: #45454533;
$secondary-t-10: #4545451A;

$color1: #1A1AB5FF;
$color1-t-90: #1A1AB5E6;
$color1-t-80: #1A1AB5CC;
$color1-t-70: #1A1AB5B3;
$color1-t-60: #1A1AB599;
$color1-t-50: #1A1AB580;
$color1-t-40: #1A1AB566;
$color1-t-30: #1A1AB54D;
$color1-t-20: #1A1AB533;
$color1-t-10: #1A1AB51A;

$color2: #FF92A7FF;
$color2-t-90: #FF92A7E6;
$color2-t-80: #FF92A7CC;
$color2-t-70: #FF92A7B3;
$color2-t-60: #FF92A799;
$color2-t-50: #FF92A780;
$color2-t-40: #FF92A766;
$color2-t-30: #FF92A74D;
$color2-t-20: #FF92A733;
$color2-t-10: #FF92A71A;

$color3: #B0E5EDFF;
$color3-t-90: #B0E5EDE6;
$color3-t-80: #B0E5EDCC;
$color3-t-70: #B0E5EDB3;
$color3-t-60: #B0E5ED99;
$color3-t-50: #B0E5ED80;
$color3-t-40: #B0E5ED66;
$color3-t-30: #B0E5ED4D;
$color3-t-20: #B0E5ED33;
$color3-t-10: #B0E5ED1A;

$color4-90: #2b2412;
$color4-80: #50431a;
$color4-70: #786521;
$color4-60: #a38a28;
$color4-50: #d0af2d;
$color4: #FFD731;
$color4-40: #ffdd5c;
$color4-30: #ffe47f;
$color4-20: #ffeaa0;
$color4-10: #fff1c0;
$color4-0: #fff8df;
$color4-t-90: #FFD731E6;
$color4-t-80: #FFD731CC;
$color4-t-70: #FFD731B3;
$color4-t-60: #FFD73199;
$color4-t-50: #FFD73180;
$color4-t-40: #FFD73166;
$color4-t-30: #FFD7314D;
$color4-t-20: #FFD73133;
$color4-t-10: #FFD7311A;

$color5: #FE6D34FF;
$color5-t-90: #FE6D34E6;
$color5-t-80: #FE6D34CC;
$color5-t-70: #FE6D34B3;
$color5-t-60: #FE6D3499;
$color5-t-50: #FE6D3480;
$color5-t-40: #FE6D3466;
$color5-t-30: #FE6D344D;
$color5-t-20: #FE6D3433;
$color5-t-10: #FE6D341A;

$color6: #02704BFF;
$color6-t-90: #02704BE6;
$color6-t-80: #02704BCC;
$color6-t-70: #02704BB3;
$color6-t-60: #02704B99;
$color6-t-50: #02704B80;
$color6-t-40: #02704B66;
$color6-t-30: #02704B4D;
$color6-t-20: #02704B33;
$color6-t-10: #02704B1A;

$success-color-font: rgb(3 84 63);
$success-color-border: rgb(49 196 141);
$success-color-background: rgb(222 247 236);

$danger-color-font: rgb(155 28 28);
$danger-color-border: rgb(249 128 128);
$danger-color-background: rgb(253 232 232);

$info-color-font: rgb(30 66 159);
$info-color-border: rgb(118 169 250);
$info-color-background: rgb(225 239 254);

$warning-color-font: rgb(114 59 19);
$warning-color-border: rgb(250 202 21);
$warning-color-background: rgb(253 253 234);

$dark-color-font: rgb(31 41 55);
$dark-color-border: rgb(209 213 219);
$dark-color-background: rgb(249 250 251);
