/*
---------------
1.4 Header
---------------*/
@use '@angular/material' as mat;

mat-toolbar {
    .toolbar-avatar {
        width: 40px;
        height: 40px;
        line-height: 24px;
    }
    .toolbar-avatar img {
        width: 40px;
        border-radius: 50%;
    }
    .notification-label {
        position: absolute;
        top: 0;
        left: 50%;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 0.8125rem;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        background-color: mat.get-color-from-palette($warn);
        color: white;
        text-align: center;
    }
    &.main-header {
        padding: 0 0.3125rem;
        background: transparent;
        position: relative;
        box-shadow: 0 7px 6px rgba(0, 0, 0, 0.05);
        background: $main-header-bg-color;
        z-index: 1;
    }
}

.sidebar-logo {
    height: $navbar-height;
    background-color: #f33;
    padding: 1rem 0;
    h2 {
        margin: 0;
        font-size: 1.8rem;
    }
}

.opt-menu {
    .mat-mdc-menu-content {
        padding-top: 0;
    }
    .head-menu {
        font-size: 1.3rem !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        background: linear-gradient(58deg, #f44336, #ff3333);
        color: #fff;
        &:hover {
            background: linear-gradient(58deg, #f44336, #ff3333);
        }
    }
    img {
        border-radius: 100%;
        box-shadow: 0px 0 4px 0 rgba(0, 0, 0, 0.6) !important;
        border: 2px solid rgba(0, 0, 0, 0.2);
        margin-right: 0.4rem;
    }
}

// Specific height for mobile devices in portrait mode.
@media (max-width: 599px) and (orientation: portrait) {
    mat-toolbar {
        &.main-header {
            .logo {
                width: 87px;
                height: 20px;
                -webkit-background-size: 87px 20px;
                background-size: 87px 20px;
            }
        }
    }
}

// Specific height for mobile devices in landscape mode.
@media (max-width: 959px) and (orientation: landscape) {
    mat-toolbar {
        &.main-header {
            .search-bar {
                .search-form {
                    input {
                        padding: 0.65rem 0.75rem;
                    }
                }
            }
        }
    }
}

.messages-list .mat-expansion-indicator {
    display: none;
}

@media (max-width: 768px) {
    .name-user {
        display: none;
    }
    .img-thumb img {
        margin-right: 3rem !important;
    }
}


@media (max-width: 600px) {
    mat-toolbar {
        &.main-header {
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
        }
    }
}

//
