@use '@angular/material' as mat;
@use './scss/palette' as color;

@import 'scss/typography';
@import "scss/app.variables";
@import "scss/material";

@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins

@import "scss/mixins";
// Core Styles
@import "scss/core";
@import "scss/material";
@import "scss/utilities/utilities";

@import 'scss/adp-table';
/*
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";*/



@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
    white-space: normal !important;
}

.mat-sidenav-content {
    background: #e5e5e5 !important;
}

.menuitem-name {
    text-transform: uppercase;
    letter-spacing: 0.07em;
    font-size: 0.9em;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    margin: 5% auto;
    width: 750px;
    background-color: white;
    border-radius: 3px;
    max-width: 80vw;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    animation-name: modalopen;
    animation-duration: var(--modal-duration);
}

.modal-header h2,
.modal-footer h3 {
    margin: 0;
}

.modal-header {
    background: var(--modal-color);
    padding: 15px;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-body {
    padding: 10px 20px;
    background: #fff;
}

.modal-footer {
    background: var(--modal-color);
    padding: 10px;
    color: #fff;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.mat-mdc-cell {
    font-size: 0.9em !important;
}

.big-modal {
    width: 600px;
    max-width: 80vw;
}

@keyframes modalopen {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.expand .mat-expansion-panel .mat-expansion-panel-body {
    padding: 0;
}

.labelStatus {
    display: inline;
    padding: 0.3em 0.6em;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}
.badge {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    margin: 8px 0px;
    height: 20px;
    line-height: 10px;
    width: 80px;
    text-align: center;
    border-radius: 2px;
}

.badge-primary {
    background-color: color.$color1;
}

.badge-info {
    background-color: color.$color3;
}

.badge-warning {
    background-color: color.$color4;
}

.badge-success {
    background-color: color.$color5;
}

.badge-danger {
    background-color: color.$color6;
}

.detail-container {
    max-height: 60vh;
    overflow: auto;
    width: 100%;
}

.detail-container::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}

.detail-container::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 5px;
}

.mat-mdc-menu-item.thin {
    line-height: 35px !important;
    height: 35px !important;
    padding: 0 10px !important;
}

.mat-mdc-menu-item.thin .mat-icon {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    font-size: 20px;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-group.min .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px !important;
}

.mat-stepper-horizontal.min .mat-horizontal-content-container {
    padding: 0 13px 16px 13px !important;
}

.mat-stepper-horizontal.min .mat-horizontal-stepper-header {
    height: 40px !important;
}

.mat-expansion-panel.mini {
    border-radius: 0;
    box-shadow: none !important;
    & .mat-expansion-panel-header {
        height: 30px !important;
    }
}

.mat-expansion-panel.expand-min {
    border-radius: 0;
    box-shadow: none !important;
    border-bottom: 1px solid #aaa;
    & .mat-expansion-panel-header {
        height: 30px !important;
        padding: 0 15px !important;
        &.mat-expanded {
            background-color: #f6f6f6;
        }
    }
    & .mat-expansion-panel-body {
        padding: 8px 2px;
    }
}

.mat-expansion-panel-spacing.expand-min {
    margin: 0 !important;
}

.ellipsis-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
    font-size: 0.9em;
}

/*.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #f4f4f4 !important;
}

.select2-container--default .select2-selection--multiple {
    border-radius: 0px !important; 
    border-bottom: 1px solid #aaa;
    border-top: none;
    border-right-width: 0;
    border-left-width: 0;
    padding-top: 5px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-top: none;
    border-right-width: 0;
    border-left-width: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #ffffff !important;
    border-radius: 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    border-right: none !important; 
    font-weight: normal !important; 
}*/

/*
TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-chip-list.multi .mat-chip-list-wrapper {
    margin: -2px 0 !important;
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.multi-select .mat-form-field-infix {
    border-top: none !important;
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.multi-select.mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0 !important;
}

/*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-form-field.multi-select .mat-select-value-text {
    white-space: break-spaces !important;
}

/*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-multiple .mat-select-trigger{
    display: inline-table;
    /*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value{
        display: table-cell;
    }
    /*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-arrow-wrapper{
        display: table-cell;
    }
}

.linked {
    background-color: #e9e6ff !important;
}

.noSpokesmen {
    background-color: #f8d7da !important;
}

.mat-mdc-basic-chip.mat-mdc-chip.chipName {
    align-items: center;
    border-radius: 3px;
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    display: inline-flex;
    margin: 2px;
    padding: 3px 5px;
    align-items: center;

    & span {
        box-sizing: border-box;
        font-weight: 400;
        line-height: 1;
        margin: 0;
    }
    & .mat-icon {
        font-size: 12px;
        height: 12px;
        width: 12px;
        margin-right: 4px;
        cursor: pointer;
        color: #cbcbcb;
    }
}

.jp-month-picker{
    mat-calendar-header{
        display: none;
    }
    .mat-calendar-content{
        padding-top: 25px;
    }
    .mat-calendar-body-label{
        display: none;
    }
}
.jp-year-picker{
  .mat-calendar-period-button {
    pointer-events: none;
  }
  .mat-calendar-arrow {
    display: none;
  }
}
mat-form-field.multiple--select{
    /*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    /*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    mat-select{
        /*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-trigger{
          display: flex !important;
        }
    }
} 

/*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: color.$primary !important;
}

/**
*   21 - 02 - 2024
*/
/** Header de las tarjetas */
.adp-card-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center center;
}

.animate-spin {
    animation: spin 1s linear infinite;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.animate-pulse {
    animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    @keyframes pulse {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: .5;
        }
    }
}

/** Foto de perfil en formulario de usuario */
.avatar-profile-previsualizer {
    width: 180px;
    height: 180px;
}

.flex-1 {
    flex: 1 1 auto;
}

mat-toolbar{
    background-color: white !important;
}
