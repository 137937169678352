/*-------------------
 * Material Theme
 *-------------------*/
@use '@angular/material' as mat;

@include mat.core();

$mat-agente-primary-palette: (
  50 : #ffe7e7,
  100 : #ffc2c2,
  200 : #ff9999,
  300 : #ff7070,
  400 : #ff5252,
  500 : #ff3333,
  600 : #ff2e2e,
  700 : #ff2727,
  800 : #ff2020,
  900 : #ff1414,
  A100 : #ffffff,
  A200 : #fffbfb,
  A400 : #ffc8c8,
  A700 : #ffaeae,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-agente-accent-palette: (
  50 : #e1eee9,
  100 : #b3d4c9,
  200 : #81b8a5,
  300 : #4e9b81,
  400 : #288566,
  500 : #02704b,
  600 : #026844,
  700 : #015d3b,
  800 : #015333,
  900 : #014123,
  A100 : #76ffb4,
  A200 : #43ff97,
  A400 : #10ff7b,
  A700 : #00f66e,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-agente-warn-palette: (
  50 : #ffede7,
  100 : #ffd3c2,
  200 : #ffb69a,
  300 : #fe9971,
  400 : #fe8352,
  500 : #fe6d34,
  600 : #fe652f,
  700 : #fe5a27,
  800 : #fe5021,
  900 : #fd3e15,
  A100 : #ffffff,
  A200 : #fffaf9,
  A400 : #ffcec6,
  A700 : #ffb9ad,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

/** Light Theme (Default)*/
$primary: mat.define-palette($mat-agente-primary-palette);
$accent: mat.define-palette($mat-agente-accent-palette);
$warn: mat.define-palette($mat-agente-warn-palette);

$theme: mat.define-light-theme(
  (
    color: (
        primary: $primary, 
        accent: $accent, 
        warn: $warn
    ),
    typography: $typography,
    density: 0
  )
);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme

@include mat.all-component-themes($theme);