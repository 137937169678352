mat-calendar.without-year-selector {
    mat-calendar-header {
        button.mat-calendar-period-button {
            pointer-events: none;
            svg.mat-calendar-arrow {
                display: none;
            }
        }
    }
}

button.custom-calendar-button {
    background-color: #ff333333;
}