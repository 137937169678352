@use '../palette' as color;

.adp-badge {
    display: inline-flex;
    justify-items: center;
    padding: 4px 8px 2px 8px;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 2px;
    min-width: 90px;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
}

.adp-badge-success {
    color: color.$success-color-font !important;
    background-color: color.$success-color-background !important;
    border-color: color.$success-color-border !important;
}

.adp-badge-danger {
    color: color.$danger-color-font !important;
    background-color: color.$danger-color-background !important;
    border-color: color.$danger-color-border !important;
}

.adp-badge-info {
    color: color.$info-color-font !important;
    background-color: color.$info-color-background !important;
    border-color: color.$info-color-border !important;
}

.adp-badge-warning {
    color: color.$warning-color-font !important;
    background-color: color.$warning-color-background !important;
    border-color: color.$warning-color-border !important;
}

.adp-badge-dark {
    color: color.$dark-color-font !important;
    background-color: color.$dark-color-background !important;
    border-color: color.$dark-color-border !important;
}