
$mat-fab-size: 56px !default;
$mat-fab-padding: 16px !default;

[mat-raised-button],
[mat-fab],
[mat-mini-fab],
[mat-stroked-button],
[mat-button],
[mat-icon-button] {
  border-radius: $border-radius-base;
  font-weight: $font-weight-base;
  font-family: "Nexa", "Helvetica Neue", sans-serif !important;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  &[mat-button-sm] {
    font-size: 12px;
    line-height: 25px;
    padding: 2px 5px;
    min-width: 35px;
    min-height: 35px;
    mat-icon {
      font-size: 21px;
      height: 21px;
      width: 21px;
      margin: 0px;
    }
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-raised-button.mat-primary.mat-button-disabled {
  opacity: 0.65;
  color: white;
  background-color: #ff3333;
}

[mat-icon-button] {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  &[mat-button-sm] {
    padding: 0;
    width: 30px;
    height: 30px;
    mat-icon {
      font-size: 20px;
      line-height: 20px;
      margin: 0px;
    }

  }
}

@mixin mat-fab-position($spot, $top: auto, $right: auto, $bottom: auto, $left: auto) {
  [mat-fab].mat-mdc-fab-#{$spot} {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: fixed;
  }
}

$mat-fab-pos-offset: calc( ($mat-fab-size - $mat-fab-padding ) / 2 );
@include mat-fab-position(bottom-right, auto, $mat-fab-pos-offset, 1rem, auto);
@include mat-fab-position(bottom-left, auto, auto, -25px, $mat-fab-pos-offset);
@include mat-fab-position(top-right, $mat-fab-pos-offset, $mat-fab-pos-offset, auto, auto);
@include mat-fab-position(top-left, $mat-fab-pos-offset, auto, auto, $mat-fab-pos-offset);
