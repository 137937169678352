@use './palette' as palette;

/** UTILIZADOS */
$background-loading: rgba(0, 0, 0, 0.15);
$active-color: palette.$primary;
$selected-row-background-color: rgba(255, 200, 200, 0.3);
$row-hover-color: rgba(255, 200, 200, 0.1);
$no-programs-row: rgba(255, 51, 51, 0.4);
$background-color: #fff;
$header-background-color: #fff;
$border-color: #babfc7;
$foreground-color: #181d1f;
$odd-row-background-color: #fdfdfd;

    .loading {
        position: absolute;
        background: $background-loading;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .no-programs-row {
        background-color: rgba(255, 51, 51, 0.5) !important;
    }

    .row-selected {
        background-color: $selected-row-background-color !important;
    }
