@font-face {
    font-family: "Nexa";
    src: url("Nexa-Light.otf");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "Nexa";
    src: url("Nexa-Regular.otf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Nexa";
    src: url("Nexa-Bold.otf");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Nexa";
    src: url("Nexa-Heavy.otf");
    font-style: normal;
    font-weight: 900;
}

$nexa-family: '"Nexa", "Helvetica Neue", sans-serif;';