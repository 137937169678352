/* $Widget
 ------------------------------------------*/
 @use '@angular/material' as mat;
 .ngx-datatable.material {
   padding: 0;
   margin: calc( $gutter / 3 );
   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
   @include mat.elevation-transition;
   display: block;
   position: relative;
   border-radius: $border-radius-base;
   &.fullscreen {
     position: absolute !important;
     height: auto !important;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
   }
   &.striped {
     .datatable-row-odd {
       background: mat.get-color-from-palette($background, "hover");
     }
   }
   &.page {
     height: 65vh;
     max-height: 550px;
     min-height: 350px;
   }
   &.min {
     height: 55vh;
     max-height: 450px;
     min-height: 350px;
   }
 
   &.single-selection,
   &.multi-selection {
     .datatable-body-row {
       &.active,
       &.active:hover,
       &.active:hover .datatable-row-group {
         background-color: darken(mat.get-color-from-palette($primary), 2%);
         color: #fff;
       }
 
       &.active:focus,
       &.active:focus .datatable-row-group {
         background-color: darken(mat.get-color-from-palette($primary), 4%);
         color: #fff;
       }
     }
   }
 
   &.cell-selection {
     .datatable-body-cell {
       &:hover,
       &:hover .datatable-row-group {
         background: mat.get-color-from-palette($background, "hover");
       }
 
       &:focus,
       &:focus .datatable-row-group {
         background-color: darken(mat.get-color-from-palette($background, "hover"), 2%);
       }
 
       &.active,
       &.active:hover,
       &.active:hover .datatable-row-group {
         background-color: darken(mat.get-color-from-palette($primary), 2%);
         color: #fff;
       }
 
       &.active:focus,
       &.active:focus .datatable-row-group {
         background-color: darken(mat.get-color-from-palette($primary), 4%);
         color: #fff;
       }
     }
   }
 
   .empty-row {
     padding: 0.85rem 1.2rem;
   }
 
   .loading-row {
     padding: 0.85rem 1.2rem;
   }
 
   .datatable-header {
     border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
     min-width: 100%;
     .datatable-header-cell {
       padding: 0.85rem 1.2rem;
       color: rgba(mat.get-color-from-palette($foreground, base), 0.87);
       vertical-align: middle;
 
       &.longpress {
         color: #00e676;
       }
 
       &.dragging {
         .resize-handle {
           border-right: none;
         }
       }
     }
 
     .resize-handle {
       border-right: solid 1px mat.get-color-from-palette($foreground, divider);
     }
   }
 
   .datatable-body {
     min-width: 100%;
     .datatable-row-detail {
       background: #f5f5f5;
       padding: 10px;
     }
 
     .datatable-body-row {
       border-bottom: none ; 
       .datatable-body-cell {
         padding: 0.85rem 1.2rem;
         box-sizing: border-box;
         max-height: 100%;
         display: flex;
         flex-direction: row;
         -webkit-box-orient: horizontal;
         -webkit-box-direction: normal;
         justify-content: flex-start;
         align-items: center;
         align-content: center;
         -webkit-box-pack: start;
         -webkit-box-align: center;
         .datatable-body-cell-label {
           width: 100%;
         }
       }
     }
 
     .progress-linear {
       .container {
         background-color: #fc585881;
 
         .bar {
           background-color: #ff3333;
         }
       }
     }
   }
 
   .datatable-footer {
     border-top: 1px solid mat.get-color-from-palette($foreground, divider);
 
     .page-count {
       line-height: 40px;
       height: 40px;
     }
 
     .datatable-pager {
       li {
         vertical-align: middle;
 
         a {
           height: 20px;
           min-width: 20px;
           margin: 0px 3px;
           position: relative;
         }
 
         &.disabled a {
           color: rgba(0, 0, 0, 0.26) !important;
           background-color: transparent !important;
         }
 
         &.active a {
           background: mat.get-color-from-palette($primary);
           color: white;
           font-weight: $font-weight-base;
         }
 
         // Nuevos estilos para las flechas
         &:nth-child(2) a::before,
         &:nth-last-child(2) a::before {
           content: '';
           border: solid rgba(0, 0, 0, 0.54);
           border-width: 0 2px 2px 0;
           display: inline-block;
           padding: 3px;
           position: absolute;
           top: 50%;
           left: 50%;
         }
 
         &:nth-child(2) a::before {
           transform: translate(-50%, -50%) rotate(135deg);
         }
 
         &:nth-last-child(2) a::before {
           transform: translate(-50%, -50%) rotate(-45deg);
         }
       }
     }
   }
 }
 
 .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
   background-color: mat.get-color-from-palette($primary);
 }
 
 @media (max-width: 700px) {
   .ngx-datatable.material {
     margin: 0;
     box-shadow: none !important;
 
     &.scroll-vertical {
       height: 70vh;
       max-height: 550px;
       min-height: 350px;
     }
   }
 }