/*---------------
3.5 Material 
---------------*/
@import "material/theme";
@import "material/buttons";
@import "material/cards";
@import "material/dialog";
@import "material/lists";
@import "material/select";
@import "material/menus";
@import "material/tabs";
@import "material/chips";

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple{
    background-color: #ff3333;
}

.mat-mdc-form-field-error {
    color: #ff3333;
}

.mat-mdc-form-field {
    line-height: 1.275 !important;
}