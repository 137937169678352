/*
---------------
1.3 Sidebar Panel
---------------*/
@use '@angular/material' as mat;

mat-drawer.sidebar-panel {
    overflow-x: hidden;
    width: $sidebar-width;
    /*box-shadow: $base-card-box-shadow !important;*/
    .mat-sidenav-focus-trap > .cdk-focus-trap-content {
        position: relative;
    }
    > nav {
        min-width: $sidebar-width;
    }
    /*
TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-mdc-list-item span.mat-list-item-content {
        display: block;
        height: auto;
        max-height: 48px;
        overflow: hidden;
        padding: 0;
        -webkit-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
        -moz-transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
        transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    }
    .mat-nav-list .mat-mdc-list-item {
        height: auto;
        background-color: transparent;
        -webkit-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
        -moz-transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
        transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    }
    /*
TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-mdc-list-item.open > span.mat-list-item-content {
        max-height: 1000px;
        // background: mat-color($background, 'hover');
        background: rgba(168, 160, 160, 0.2);
    }
    .mat-nav-list a {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 48px;
        padding: 0 16px;
        color: $sidebar-menu-color;
    }
    .sub-menu {
        padding-top: 0;
        overflow: hidden;
        -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
        -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
        transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
        -webkit-transition-property: max-height;
        -moz-transition-property: max-height;
        transition-property: max-height;
    }
    .sub-menu a {
        @include padding-left($navbar-height);
    }
    .sub-menu .sub-menu a {
        @include padding-left($navbar-height + 16px);
    }
    .sub-menu .sub-menu .sub-menu a {
        @include padding-left($navbar-height + 32px);
    }
    .sub-menu .sub-menu .sub-menu .sub-menu a {
        @include padding-left($navbar-height + 48px);
    }
    .navigation {
        mat-icon:not(.menu-caret) {
            @include margin-right(20px);
        }
        .menu-caret {
            display: inline-block;
            -webkit-transition: -webkit-transform $transition-duration cubic-bezier(0.7, 0, 0.3, 1);
            -moz-transition: -moz-transform $transition-duration cubic-bezier(0.7, 0, 0.3, 1);
            -o-transition: -o-transform $transition-duration cubic-bezier(0.7, 0, 0.3, 1);
            transition: transform $transition-duration cubic-bezier(0.7, 0, 0.3, 1);
            @include float(right);
            @include margin-left(0.3125rem);
            text-align: center;
        }
        /*
TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        .open > .mat-list-item-content > [appAccordionToggle] > .menu-caret {
            @include rotate(-180deg);
        }
        .menu-badge {
            display: inline-block;
            height: 16px;
            min-width: 10px;
            line-height: 18px;
            text-align: center;
            border-radius: 16px;
            font-size: 10px;
            font-weight: 700;
            padding: 0 4px;
        }
        /*
TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        .open > .mat-list-item-content > .sub-menu {
            max-height: 1000px;
        }
    }
}

/* .mat-drawer-content {
    display: flex !important;
} */

.user-profile-thumb {
    img {
        width: 100px;
        height: 100px;
        margin-bottom: 12px;
    }
}

.user-pro-wrap {
    padding: 1rem 0.5rem;
    position: relative;
    border-bottom: 2px solid #f33;
}

.most-link {
    display: inline-block;
    color: $profile-link;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 12px;
    .mat-icon {
        margin-top: 0.8rem;
    }
}

.sidebar-panel {
    background-color: white;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
}

.mat-mdc-card.settings-panel .demo-checkbox {
    height: 100% !important;
    overflow-y: auto;
}

[mat-fab].mat-mdc-fab-bottom-right {
    top: 47% !important;
    right: 0 !important;
    position: fixed !important;
    border-radius: 0 !important;
    z-index: 9;
}

.user-i {
    z-index: 99;
    position: relative;
    span {
        font-weight: bold;
        font-size: 1rem;
        padding: 0 0.5rem;
        letter-spacing: 0.05em;
    }
}

.bg-overlay {
    position: absolute;
    left: 0;
    z-index: -1;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.85;
    //background-image: linear-gradient(58deg, #da3c3c, #ff3333);
    background-color: #fff;
}

.mat-sidenav-content {
    overflow-x: hidden !important;
    padding: 0 !important;
    background: $main-content;
    display: flex;
}

.body-container {
    height: calc(100% - $navbar-height);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    padding: 1rem 2rem;
}

@media (max-width: 991px) {
    .body-container {
        padding: 1rem;
    }
    html {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .body-container {
        padding: 0rem;
    }
}

body {
    .opt-side {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .app {
        height: 100%;
        overflow: hidden;
    }
    .app-inner {
        height: 100%;
    }
    .main-nav {
        height: calc(100% - 144px);
    }
}

.m-gap {
    margin-bottom: $card-spacing;
}

.p-gap {
    padding-left: calc($card-spacing / 2);
    padding-right: calc($card-spacing / 2);
}

//border active
.demo-checkbox {
    img,
    i {
        border: 3px solid transparent;
    }
    .border-active {
        img,
        i {
            border: 3px solid #ffd740;
        }
        i {
            border-radius: 10px;
        }
    }
}

.app {
    overflow-x: hidden;
}
/* Main*/

.widget-icon {
    width: 50px;
    height: 50px;
    line-height: 65px;
    text-align: center;
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin: 0 1rem;
    background: rgba(255, 255, 255, 0.1);
}

.grid-corner {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.2);
    padding: 15px 12px 22px 24px;
    border-radius: 0 0 0 100%;
    transition: all 0.3s ease-in-out 0s;
}

.card-header h5,
h3 {
    margin-top: 0;
}

.base-card {
    .fa-lg {
        transition: all 0.3s ease-in-out 0s;
    }
    &:hover {
        .fa-lg {
            font-size: 2rem;
        }
    }
}

.base-text {
    i {
        float: left;
        margin-right: 10px;
        margin-top: 8px;
    }
}

//card profile
.pro-card {
    padding: 3rem 2rem;
}

.pro-img {
    img {
        border-radius: 100%;
        box-shadow: $base-card-box-shadow;
    }
}

.profile-share {
    padding: 0;
    li {
        display: inline-block;
        a {
            font-size: 1rem;
            padding: 0 0.2rem;
        }
    }
}

//wheather widget
.wheather-widget {
    .wheather-today {
        padding-top: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        i {
            font-size: 6rem;
        }
    }
    .wheather-future {
        padding: 1rem 0;
        i {
            font-size: 3rem;
        }
    }
    .wheather-child {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

//comment
.w-50 {
    width: 50px;
}

.time {
    display: block;
}

.comment-time {
    display: block;
    right: 1rem;
    font-size: 78%;
    margin-left: 2rem;
    opacity: 0.7;
}

.message-content .shadow-none.mat-mdc-card {
    padding: 1rem;
}

.mat-mdc-row {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.mat-pad {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

body .mat-mdc-card > :last-child {
    border: 2px;
}

//product detail
body .sidebar-container {
    height: 100%;
    position: relative;
}

@media (max-width: 543px) {
    .search-bar {
        width: 110px;
    }
    .search-bar.search-active {
        width: calc(100% - 60px);
        position: absolute;
        flex: 1 1 0%;
        z-index: 99;
        left: 50px !important;
        right: auto !important;
    }
    .sm-disable {
        display: none !important;
    }
    .search-form {
        .mat-icon {
            display: none;
        }
        input {
            padding: 0.95rem 0.5rem !important;
            text-indent: 0 !important;
            font-size: 0.8rem !important;
        }
    }
}
