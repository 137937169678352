.authentication-container {
    height: 100vh !important;
    width: 100vw !important;
    position: relative;
}

.background-login {
    background-image: url("/../assets/images/login-background.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    height: 100vh;
}

