.mat-mdc-snack-bar-container {
    padding: 8px !important;
}
.adp-snackbar {
    font-weight: 600;
    font-family: 'Nexa', Arial, Helvetica, sans-serif;
    simple-snack-bar.mat-mdc-simple-snack-bar{
        justify-content: center !important;
    }
    .snackbar-icon {
        width: 30px; 
        height: 30px; 
        display: flex; 
        margin: auto 10px auto 0;
    }
    .snackbar-title {
        margin: 0; 
        font-weight: 600; 
        font-size: 12px;
    }
    .snackbar-label {
        margin: 0; 
        font-size: 10px;
    }
    .snackbar-button {
        cursor: pointer;
        border-radius: 4px;
        max-width: 85px;
        min-width: 65px;
        padding: 4px 8px;
    }
    .snackbar-button-action {
        background-color: rgb(253 242 242);
        color: rgb(155 28 28);
        border: 1px solid rgb(155 28 28);
        &:hover{
            background-color: rgb(240, 198, 198);
        }
    }
    .snackbar-button-close {
        background-color: rgb(248 180 180);
        color: rgb(155 28 28);
        border: none;
        &:hover{
            background-color: rgb(229, 154, 154);
        }
    }
}
.adp-warning-snackbar {
    background-color: rgb(253 253 234);
    border: solid .5px rgb(250 202 21);
    border-left: solid 10px rgb(250 202 21);
    color: rgb(114 59 19);
}
.adp-success-snackbar {
    background-color: rgb(243 250 247);
    border: solid .5px rgb(132 225 188);
    border-left: solid 10px rgb(132 225 188);
    color: rgb(3 84 63);
}
.adp-info-snackbar {
    background-color: rgb(235 245 255);
    border: solid .5px rgb(164 202 254);
    border-left: solid 10px rgb(164 202 254);
    color: rgb(30 66 159);
}
.adp-danger-snackbar {
    background-color: rgb(253 242 242);
    border: solid .5px rgb(248 180 180);
    border-left: solid 10px rgb(248 180 180);
    color: rgb(155 28 28);
}
.adp-dark-snackbar {
    background-color: rgb(249 250 251);
    border: solid .5px rgb(209 213 219);
    border: solid 10px rgb(209 213 219);
    color: rgb(31 41 55);
}