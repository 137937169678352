@use '@angular/material' as mat;
body {
  .mat-mdc-select-panel {
    background-color: mat.get-color-from-palette($background, card);
  }
  mat-option{
    background-color: #fff !important;
  }
  /*.mat-select-trigger, .mat-option {
    font-size: $font-size-base;
  }
  .mat-select-trigger {
    height: 22px;
    padding-bottom: 6px;
  }*/
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-placeholder {
    padding-left: 0;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
  .mat-autocomplete-panel {
    background-color: mat.get-color-from-palette($background, card);
    & .mat-mdc-option {
      line-height: 40px;
      height: 40px;
      font-size: 14px;
    }
  }
}
