/*
---------------
1.5 Main Panel
---------------*/

$mat-toolbar-height-desktop: 64px !default;
//56px-portrait: 56px !default;
//56px-landscape: 48px !default;
.app-inner {
    position: relative;
    width: 100%;
    max-width: 100%;
    @include flexbox;
    @include flex-direction(row);
}

@media (max-width: 599px) and (orientation: portrait) {
    .app-inner {
        height: calc(100vh - #{56px-portrait});
    }
}

// Specific height for mobile devices in landscape mode.

@media (max-width: 959px) and (orientation: landscape) {
    .app-inner {
        height: calc(100vh - #{56px-landscape});
    }
}


/* Boxed layout*/

.app.boxed {
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 48em) {
    .app.boxed {
        max-width: 46.88rem;
    }
}

@media (min-width: 62rem) {
    .app.boxed {
        max-width: 60.63rem;
    }
}

@media (min-width: 75rem) {
    .app.boxed {
        max-width: 73.13rem;
    }
}

.mat-sidenav-content {
    min-height: 100%;
    //background: $main-panel-bg;
    box-sizing: border-box;
    @include flex-direction(column);
    @include flexbox;
    @include flex(1);
    overflow-y: auto;
    overflow-x: hidden;
    padding: calc( $gutter / 3 );
    >.main-content {
        box-sizing: border-box;
        @include flex-direction(column);
        @include flexbox;
        @include flex(1);
        >.content-view {
            position: relative;
            box-sizing: border-box;
            padding: $gutter;
            @include flex(1 0 auto);
        }
    }
}

//Top Menu

.top-menu {
    .horizontal-logo{
        display: block;
    }
    .sidebar-panel{
        display: none;
    }
     .mat-drawer-content.mat-sidenav-content {
        margin: 0 !important;
        overflow: hidden;
    }
    .horizontal-menu {
        background: #fff;
        box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
        .main-h-list {
            padding: 0;
            margin: 0;
            >li {
                display: inline-block;
                >a {
                    display: flex;
                    padding: 1rem 1rem;
                    margin: 0 0.5rem;
                }
                &:hover {
                    >.dropdown {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0px);
                    }
                }
            }
            li {
                position: relative;
            }
            .dropdown {
                position: absolute;
                opacity: 0;
                visibility: hidden;
                width: 200px;
                transition: all 0.2s ease-in-out 0s;
                transform: translateY(-20px);
                background: #fff;
                padding: 0;
                list-style: none;
                text-align: left;
                z-index: 9;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                a {
                    padding: 0.5rem 1rem;
                    display: block;
                    &:hover{
                        background: #f2f4f8;
                    }
                }
                li:hover {
                    >.dropdown {
                        opacity: 1;
                        visibility: visible;
                          transform: translateY(0px);
                    }
                }
            }
            .dropdown .dropdown {
                top: 0;
                left: 100%;
            }
            .mat-icon {
                margin-right: 0.5rem;
            }
        }
    }
    .menu-links{
    text-align: right;
    }

}
